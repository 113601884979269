<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper marginB20">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <el-form-item :label="$t('searchModule.order_ID')" prop="rechargeId">
            <el-input v-model.trim="formInline.rechargeId" placeholder="请输入订单ID"></el-input>
          </el-form-item>
          <el-form-item label="对方交易单号" prop="tradeNo">
            <el-input v-model.trim="formInline.tradeNo" placeholder="请输入对方交易单号"></el-input>
          </el-form-item>
          <el-form-item label="充值手机号" prop="mobile">
            <el-input v-model.trim="formInline.mobile" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Payment_channels')" prop="payChannel">
            <el-select v-model.trim="formInline.payChannel" filterable size="15">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.channelTypeDesc"
                :value="value.channelType"
                :key="value.channelType"
                v-for="value in payType"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间搜索" prop="operationId">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="range"
              type="datetimerange"
              range-separator="至"
              unlink-panels
              :default-time="['00:00:00', '23:59:59']"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="" prop="dealState">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                getData();
                page = 1;
              "
              :loading="loading"
              >{{ $t('button.search') }}</el-button
            >
            <el-button type="primary" plain icon="el-icon-search" @click="clear" :loading="loading"
              >{{ $t('button.reset') }}</el-button
            >
            <el-button
              type="primary"
              v-if="$route.meta.authority.button.export"
              icon="el-icon-upload2"
              @click="exportFile"
              :loading="loading"
              >导出
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper paddingB20">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <div class="pagerWrapper" v-if="total > 0">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, exportExcelNew } from "../../common/js/public.js";
import moment from "moment";
let startTime = moment().format("YYYY-MM-DD");

export default {
  name: "collectorIncome",
  data() {
    return {
      range: [startTime + " 00:00:00", startTime + " 23:59:59"],
      page: 1,
      pageSize: 15,
      total: 0,
      index: 0,
      loading: false,
      detailFlag: false,
      areaList: [],
      parkList: [],
      tenantList: [],
      rsTypeList: {},
      tableCols: [
        {
          prop: "rechargeId",
          label: this.$t("list.order_ID"),
          width: "",
        },
        {
          prop: "tradeNo",
          label: "对方交易单号",
          width: "",
        },
        {
          prop: "memberId",
          label: "会员ID",
          width: "",
        },
        {
          prop: "money",
          label: "充值金额",
          width: "",
          formatter: (row, column, cellValue, index) => {
            return cellValue / 100 + "元";
          },
        },
        {
          prop: "payChannelDesc",
          label: this.$t("list.Payment_channels"),
          width: "",
        },
        //		{
        //          prop: 'payTypeDesc',
        //          label: '支付方式',
        //          width: ''
        //        },
        {
          prop: "mobile",
          label: "手机",
          width: "",
        },
        {
          prop: "payTime",
          label: this.$t("list.payment_time"),
          width: "",
        },
      ],
      tableData: [],
      payType: [],
      formInline: {
        rechargeId: "",
        tradeNo: "",
        mobile: "",
        payChannel: "",
        startPayTime: "",
        endPayTime: "",
      },
    };
  },
  methods: {
    clear() {
      this.formInline = {
        rechargeId: "",
        tradeNo: "",
        mobile: "",
        payType: "",
        startPayTime: "",
        endPayTime: "",
      };
      this.range = [startTime + " 00:00:00", startTime + " 23:59:59"];
    },
    checkDate() {
      let [start, end] = this.range;
      let diff = moment(end).diff(start, "day");
      if (diff >= 180) {
        this.$alert("时间不能超过180天");
        return false;
      }
      return true;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    // 导出
    exportFile() {
      if (!this.checkDate()) return;
      let [startPayTime, endPayTime] = this.range;
      exportExcelNew("/acb/2.0/elceRecharge/export", {
        ...this.formInline,
        startPayTime,
        endPayTime,
      });
    },
    getPayType() {
      //      let url = '/acb/2.0/elceRecharge/payChannelType/list'
      //       this.$axios.get(url).then(res => {
      //        if (res.state != 0) {
      //          this.$alert(res.desc, this.$t('pop_up.Tips'), {
      //            confirmButtonText: this.$t('pop_up.Determine')
      //          })
      //        } else {
      //          this.payType = res.value;
      //        }
      //      })

      this.$axios.get("/acb/2.0/payOrder/payChannelTypeDictList").then((res) => {
        if (res.state == 0) {
          this.payType = res.value || [];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getData() {
      if (!this.checkDate()) return;
      let [startPayTime, endPayTime] = this.range;
      let url = "/acb/2.0/elceRecharge/list";
      this.$axios
        .get(url, {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
            startPayTime,
            endPayTime,
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.tableData = res.value.list;
            this.total = res.value.total * 1 || 0;
          }
        });
    },
  },
  beforeDestroy() {},
  components: {},
  // activated () {
  //   this.getData();
  // },
  created() {
    this.getData();
    this.getPayType();
    // if (sessionStorage.searchFlag == 1) {
    //   this.formInline = JSON.parse(sessionStorage.searchData);
    // }
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
.el-dropdown-link
  cursor pointer
</style>
